export const InstructionTypes = Object.freeze({
  HEA: 'HEA',
  DSC: 'DSC',
  TA: 'TA',
  TRS: 'TRS',
  TRI: 'TRI',
  TR: 'TR',
  ST: 'ST',
  SO: 'SO',
});
