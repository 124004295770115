import { Field, Form, Formik } from 'formik';
import { useRef } from 'react';
import Yup from '../../utills/myYup';
import Modal from '../../shared/modal/Modal';
import ModalButton from '../../shared/modal/ModalButton';
import Label from '../Label';
import { InputField } from '../../shared/InputField';

const TemplateDialog = ({ visible, onClose, formProps, onSubmit }) => {
  const formRef = useRef(null);

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
  });

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  return (
    <Modal
      title={formProps ? 'Editace šablony' : 'Vytvoření šablony'}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={{
          title: formProps?.title || null,
        }}
        onSubmit={(values) => {
          onSubmit(formProps?.id, values);
        }}
      >
        {(props) => {
          const { values } = props;
          return (
            <>
              <Form>
                <div>
                  <Label name="title" title="Název" required />
                  <Field
                    name="title"
                    value={values.title || null}
                    placeholder="Název"
                    component={InputField}
                  />
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default TemplateDialog;
