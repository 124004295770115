import MainContainer from '../../containers/MainContainer';
import NLeftPanel from '../../shared/components/NLeftPanel';
import { SearchContext } from '../project/building/Building';
import { useState } from 'react';
import InstructionTemplateDetail from './InstructionTemplateDetail';
import { useHistory, useParams } from 'react-router-dom';
import TemplateDialog from './TemplateDialog';
import { useMutation, useQueryClient } from 'react-query';
import { apiService } from '../../utills/api';
import { useSelector } from 'react-redux';

const InstructionsTemplates = () => {
  const company = useSelector((state) => state.auth.current_company);
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState({});
  const [showTemplateDialog, setShowTemplateDialog] = useState(null);
  const history = useHistory();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const handleActive = (id) => {
    history.push(`/app/data/sablony/instrukce/${id}`);
  };

  const createTemplate = useMutation({
    mutationFn: ({ data }) => {
      return apiService.post(`/api/v1/instruction-template/`, {
        ...data,
        company,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['instruction-template']);
      setShowTemplateDialog(false);
    },
  });

  const onSearch = async (e) => {
    setSearch(e?.target?.value);
  };

  const onFilter = async (value) => {
    setFilter(value);
  };

  return (
    <SearchContext.Provider
      value={{
        search,
        filter,
        setSearch,
        setFilter,
      }}
    >
      <MainContainer>
        <div className="flex w-full h-full">
          <NLeftPanel
            queryKey={'instruction-template'}
            makeActive={handleActive}
            active={id}
            onSearch={onSearch}
            addItem={() => setShowTemplateDialog(true)}
            onFilter={onFilter}
            defaultFilterValues={{ is_active: [true] }}
            title="Šablony instrukcí"
            permission={2}
            filteredValues={{
              is_active: [
                { value: true, title: 'Aktivní' },
                { value: false, title: 'Neaktivní' },
              ],
            }}
          />

          <InstructionTemplateDetail />
        </div>

        <TemplateDialog
          visible={showTemplateDialog}
          onClose={() => setShowTemplateDialog(false)}
          onSubmit={(_, data) => {
            createTemplate.mutate({ data });
            setShowTemplateDialog(false);
          }}
        />
      </MainContainer>
    </SearchContext.Provider>
  );
};

export default InstructionsTemplates;
