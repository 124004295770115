import { useEffect, useState, React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainContainer from '../../../containers/MainContainer';
import PanelWithClassification from '../../../shared/components/PanelWithClassification';
import {
  fetchTypeMarkTemplates,
  setActiveClassification,
  clearItems,
  fetchDetail,
  CLEAR_TYPE_MARK_TEMPLATES,
} from '../../../store/actions/typemarkTemplate';
import TypeMarkTemplateDetailView from './components/TypemarkTemplateDetail';
import TypeMarkModal from '../../project/typemark/components/modals/TypeMarkModal';
import { useMutation, useQueryClient } from 'react-query';
import { SearchContext } from '../../project/building/Building';
import NLeftPanel from '../../../shared/components/NLeftPanel';
import { apiService } from '../../../utills/api';

function TypeMarkTemplate() {
  const { pagePermission, current_company } = useSelector((state) => state.auth);
  const { contractId } = useSelector((state) => state.contract);
  const permission = useSelector((state) => state.auth.pagePermission);
  const [visibleBasicEditDialog, setVisibleBasicEditDialog] = useState(false);
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState({ is_active: [true] });
  const queryClient = useQueryClient();
  const queryKey = 'instance-type-template';

  const { activeClassification, detail, menuId } = useSelector((state) => state.typeMarkTemplate);

  const dispatch = useDispatch();

  const create = useMutation({
    mutationFn: (values) => {
      return apiService.post(`/api/v1/${queryKey}/`, {
        ...values,
        company: current_company,
        classification: activeClassification,
      });
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(queryKey);
      dispatch(fetchDetail(data.id));
      setVisibleBasicEditDialog(false);
    },
  });

  useEffect(() => {
    dispatch(clearItems());
    if (menuId && contractId) {
      queryClient.invalidateQueries(queryKey);
      dispatch({ type: CLEAR_TYPE_MARK_TEMPLATES });
    }
  }, [menuId, contractId]);

  const getDetail = (id) => {
    dispatch(fetchDetail(id));
  };

  const makeActiveClassification = (id) => {
    dispatch(setActiveClassification(id));
    dispatch(fetchTypeMarkTemplates());
  };

  const handleAddTemplate = () => {
    setVisibleBasicEditDialog(true);
  };

  const handleSubmitTypeMarkDialog = ({ values }) => {
    create.mutate(values);
  };

  const onSearch = async (e) => {
    setSearch(e?.target?.value);
  };

  const onFilter = async (value) => {
    setFilter(value);
  };

  return (
    <SearchContext.Provider
      value={{
        search,
        filter,
        setSearch,
        setFilter,
      }}
    >
      <MainContainer>
        <div className="flex">
          <PanelWithClassification
            addItem={() => {}}
            submit={() => {}}
            onSearch={() => {}}
            permission={permission}
            onFilter={() => {}}
            fetchMore={() => {}}
            url="left-panel-instance-type-template"
            filteredValues={{
              is_active: [
                { value: true, title: 'Aktivní' },
                { value: false, title: 'Neaktivní' },
              ],
            }}
            title="Třídník"
            disableAdd
            makeActive={makeActiveClassification}
            active={activeClassification}
          />
          {activeClassification && (
            <div className="border-l-2 h-full border-neutral-200">
              <NLeftPanel
                queryKey={queryKey}
                makeActive={getDetail}
                active={detail?.id}
                addItem={() => handleAddTemplate()}
                onSearch={onSearch}
                onFilter={onFilter}
                permission={permission}
                classification={activeClassification}
                title="Sablony Typemarku"
                filteredValues={{
                  is_active: [
                    { value: false, title: 'Aktivní' },
                    { value: true, title: 'Neaktivní' },
                  ],
                }}
              />
            </div>
          )}
          {detail && (
            <TypeMarkTemplateDetailView
              data={detail}
              activeItemId={detail?.id}
              permission={pagePermission}
            />
          )}
          <TypeMarkModal
            title="Vytvořit Šablonu"
            isTemplate
            visible={visibleBasicEditDialog}
            onSubmit={handleSubmitTypeMarkDialog}
            onClose={() => setVisibleBasicEditDialog(false)}
          />
        </div>
      </MainContainer>
    </SearchContext.Provider>
  );
}

export default TypeMarkTemplate;
