import { useEffect, useRef, useState } from 'react';
import Bar from '../../elements/Bar';
import Sortable from 'sortablejs';
import { Add16Regular, Edit12Filled } from '@fluentui/react-icons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { apiService } from '../../utills/api';
import { Spin } from 'antd';
import TemplateDialog from './TemplateDialog';
import InstructionDialog from './InstructionDialog';
import Instruction from './Instruction';

const InstructionTemplateDetail = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [showDialog, seShowDialog] = useState(false);
  const [showInstructionDialog, setShowInstructionDialog] = useState(false);

  const { data, error } = useQuery({
    queryKey: ['instructions', id],
    queryFn: async () => {
      const { data } = await apiService.get(`/api/v1/instruction-template/${id}`);

      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  const updateDetail = useMutation({
    mutationFn: ({ updatedData }) => {
      return apiService.patch(`/api/v1/instruction-template/${id}/`, {
        ...updatedData,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['instructions', id]);
      queryClient.invalidateQueries(['instruction-template']);
    },
  });

  const addInstruction = useMutation({
    mutationFn: ({ data }) => {
      return apiService.patch(`/api/v1/instruction-template/${id}/add-instruction`, {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['instructions', id]);
    },
  });

  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current || !data) {
      return;
    }

    const sortable = new Sortable(ref.current, {
      animation: 200,

      onEnd(evt) {
        const newItems = [...data.instructions];
        const movedItem = newItems.splice(evt.oldIndex, 1)[0];

        newItems.splice(evt.newIndex, 0, movedItem);
        updateDetail.mutate({ updatedData: { instruction_ids: newItems.map(({ id }) => id) } });
      },
      handle: '.drag-handle',
    });

    return () => {
      sortable.destroy();
    };
  }, [data]);

  return (
    <>
      <div className="bg-white flex-1 overflow-y-auto">
        <Bar
          text="Detail"
          content={[
            <Add16Regular key={1} onClick={() => setShowInstructionDialog(true)} />,
            <Edit12Filled key={1} onClick={() => seShowDialog(true)} />,
          ]}
        />

        <Bar text="Položky šablony" content={[]} />

        <ul ref={ref} className="flex flex-col gap-2 m-2">
          {error && <div>Neco se pokazilo</div>}
          {!error && !data ? (
            <div className="flex h-full w-full justify-center items-center">
              <Spin />
            </div>
          ) : (
            data.instructions.map((item) => (
              <Instruction key={item.id} templateId={id} instruction={item} />
            ))
          )}
        </ul>
      </div>
      <TemplateDialog
        visible={showDialog}
        onClose={() => seShowDialog(false)}
        formProps={data}
        onSubmit={(id, data) => {
          updateDetail.mutate({ updatedData: data });
          seShowDialog(false);
        }}
      />
      <InstructionDialog
        visible={showInstructionDialog}
        onSubmit={(_, data) => addInstruction.mutate({ data })}
        onClose={() => setShowInstructionDialog(false)}
      />
    </>
  );
};

export default InstructionTemplateDetail;
