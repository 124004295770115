import Dragger from 'antd/lib/upload/Dragger';
import React, { useRef, useState } from 'react';
import Modal from '../modal/Modal';
import ModalButton from '../modal/ModalButton';
import { apiService } from '../../utills/api';
import { Cropper } from 'react-advanced-cropper';
import debounce from 'lodash/debounce';
import { convertFileToBase64 } from '../../utills/image';
import classNames from 'classnames';
import 'react-advanced-cropper/dist/style.css';
import { NotificationManager } from 'react-notifications';

const ImageCropperDialog = ({ url, visible, aspectRatio, onSubmit, onClose, method = 'post' }) => {
  const [loading, setLoading] = useState(false);
  const croppedImageFile = useRef();
  const [cropperSource, setCropperSource] = useState(null);
  const [currentFileName, setCurrentFileName] = useState(null);

  const handleFileInputUpdate = async (file) => {
    const buffer = await convertFileToBase64(file);
    setCropperSource(buffer.toString());
  };

  const handleClose = () => {
    onClose();
    setCropperSource(undefined);
    setCurrentFileName(null);
  };

  const handleConfirm = () => {
    if (croppedImageFile.current) {
      upload();
      setCropperSource(undefined);
      setCurrentFileName(null);
    }
  };

  const handleCropperChange = debounce((cropper) => {
    cropper.getCanvas()?.toBlob(
      (blob) => {
        if (!blob) {
          return;
        }

        croppedImageFile.current = new File([blob], currentFileName, {
          type: blob.type,
        });
      },
      undefined,
      1
    );
  }, 250);

  const handleCropperReady = (cropper) => {
    handleCropperChange(cropper);
  };

  const upload = async () => {
    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    fmData.append('file', croppedImageFile.current);

    setLoading(true);
    try {
      const res = await apiService({ method: method, url, data: fmData, ...config });

      if (res.data) {
        onSubmit({ id: res.data.id, title: currentFileName });
      } else {
        onSubmit(fmData);
      }
    } catch (err) {
      console.log('Eroor: ', err);
    } finally {
      setLoading(false);
    }
  };

  const update = async (options) => {
    const { file } = options;
    setCurrentFileName(file.name);
    await handleFileInputUpdate(file);
  };

  const config = {
    name: 'file',
    multiple: true,
    accept: 'image/*',
    customRequest: update,
    showUploadList: false,
    beforeUpload: (file) => {
      const fileSize = file.size;
      const fileMb = fileSize / 1024 ** 2;

      if (fileMb >= 20) {
        NotificationManager.error('Maximální velikost je 20MB');
        return false;
      }
      return true;
    },
  };

  return (
    <Modal
      title="Nahrát soubor"
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => handleClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => handleConfirm()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <div className="flex flex-col h-auto">
        {cropperSource && (
          <div>
            <Cropper
              src={cropperSource}
              onChange={handleCropperChange}
              className={classNames('m-0 p-0', {
                'h-[400px]': cropperSource,
              })}
              onReady={handleCropperReady}
              stencilProps={{
                grid: true,
                movable: true,
                aspectRatio: aspectRatio || 1,
              }}
              transitions={true}
            />
          </div>
        )}
        <Dragger {...config}>
          <p className="ant-upload-text p-5">Klikni nebo přetáhni soubor pro jeho nahrání</p>
        </Dragger>
      </div>
    </Modal>
  );
};

export default ImageCropperDialog;
