import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainContainer from '../../containers/MainContainer';
import actionsFactory from '../../store/actions/factory/dbItemsActionFactory';
// import { debounce } from 'lodash';
import { t } from 'i18next';
import { NotificationManager } from 'react-notifications';
import { useMutation, useQueryClient } from 'react-query';
import { SearchContext } from '../../components/project/building/Building';
import NLeftPanel from '../../shared/components/NLeftPanel';
import { UPDATE_CONTRACT } from '../../store/actions/actionTypes';
import { apiService } from '../api';

const withLeftPanelWrapper = (Component, name, withVersion = false, disableAdd = false) => {
  const splittedName = name.split(/(?=[A-Z])/);
  let path = splittedName.join('-').toLowerCase();
  if (name === 'propertySet') {
    path = 'set-property-definition';
  }
  if (name === 'technicalReportItemSet') {
    path = 'set-technical-report-item';
  }
  if (name === 'contractListing') {
    path = 'contract';
  }
  if (name === 'typeMarkTemplate') {
    path = 'instance-type-template';
  }
  // eslint-disable-next-line react/display-name
  return () => {
    const permission = useSelector((state) => state.auth.pagePermission);

    const { contractId, modelId } = useSelector((state) => state.contract);
    const { detail } = useSelector((state) => state[name]);

    const [openDialog, setOpenDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [search, setSearch] = useState(null);
    const [filter, setFilter] = useState({});
    const queryClient = useQueryClient();
    const queryKey = path;

    const dispatch = useDispatch();
    const { fetchDetail, clear } = actionsFactory(name, withVersion);

    const create = useMutation({
      mutationFn: (values) => {
        return apiService.post(`/api/v1/${queryKey}/`, {
          ...values,
          contract: contractId,
          model: name === 'phase' ? values.model : modelId,
        });
      },
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries(queryKey);
        NotificationManager.success('Položka úspěšně vytvořena');
        getDetail(data.id);
        setOpenDialog(false);
      },
      onError: (error) => {
        NotificationManager.error(error?.response?.data?.detail ?? 'Něco se pokazilo');
      },
    });

    const edit = useMutation({
      mutationFn: ({ id, values }) => {
        return apiService.patch(`/api/v1/${queryKey}/${id}/`, {
          ...values,
        });
      },
      onSuccess: ({ data }, { id }) => {
        queryClient.invalidateQueries(queryKey);
        NotificationManager.success('Položka úspěšně editována');
        getDetail(id);
        setOpenDialog(false);

        if (name === 'phase') {
          dispatch({ type: UPDATE_CONTRACT, data: { modelId: data.model?.id } });
        }
      },
      onError: () => {
        NotificationManager.error('Něco se pokazilo');
      },
    });

    const getDetail = (id) => {
      dispatch(fetchDetail(id));
    };

    useEffect(() => {
      queryClient.invalidateQueries(queryKey);
      dispatch(clear());
    }, [contractId]);

    const handleSubmit = (id, values) => {
      if (id) {
        edit.mutate({ id, values });
        setIsEdit(false);
      } else {
        create.mutate(values);
      }
      setOpenDialog(false);
    };

    const handleEdit = () => {
      setIsEdit(true);
      setOpenDialog(true);
    };

    const handleClose = () => {
      setIsEdit(false);
      setOpenDialog(false);
    };

    const onSearch = async (e) => {
      setSearch(e?.target?.value);
    };

    const onFilter = async (value) => {
      setFilter(value);
    };

    return (
      <SearchContext.Provider
        value={{
          search,
          filter,
          setSearch,
          setFilter,
        }}
      >
        <MainContainer>
          <div className="flex">
            <div className="border-l-2 h-full border-neutral-200">
              <NLeftPanel
                queryKey={queryKey}
                makeActive={getDetail}
                active={detail?.id}
                onSearch={onSearch}
                addItem={() => setOpenDialog(true)}
                onFilter={onFilter}
                defaultFilterValues={{ is_active: [true] }}
                title={t(name)}
                permission={permission}
                disableAdd={disableAdd}
                filteredValues={{
                  is_active: [
                    { value: true, title: 'Aktivni' },
                    { value: false, title: 'Neaktivni' },
                  ],
                }}
              />
            </div>

            <Component
              openDialog={openDialog}
              isEdit={isEdit}
              handleSubmit={handleSubmit}
              handleEdit={handleEdit}
              handleClose={handleClose}
            />
          </div>
        </MainContainer>
      </SearchContext.Provider>
    );
  };
};

export default withLeftPanelWrapper;
