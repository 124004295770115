import {
  ArrowBidirectionalUpDown16Regular,
  Delete16Regular,
  Edit16Regular,
} from '@fluentui/react-icons';
import { useMutation, useQueryClient } from 'react-query';
import { apiService } from '../../utills/api';
import { Popconfirm } from 'antd';
import InstructionDialog from './InstructionDialog';
import { useState } from 'react';

const Instruction = ({ instruction, templateId }) => {
  const queryClient = useQueryClient();
  const [showDialog, setShowDialog] = useState(false);

  const deleteInstruction = useMutation({
    mutationFn: ({ instructionId }) => {
      return apiService.patch(`/api/v1/instruction-template/${templateId}/remove-instruction`, {
        instruction_id: instructionId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['instructions', templateId]);
    },
  });

  const editInstruction = useMutation({
    mutationFn: ({ instructionId, data }) => {
      return apiService.patch(
        `/api/v1/instruction-template/${templateId}/instruction/${instructionId}`,
        {
          instruction_id: instructionId,
          ...data,
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['instructions', templateId]);
      setShowDialog(false);
    },
  });

  return (
    <>
      <li
        key={instruction.id}
        className="drag-handle flex bg-color4 cursor-move justify-between items-center pl-4"
      >
        <div>
          <span className="text-sm mr-1">{instruction.title}</span>

          {instruction.instruction_type === 'HEA' && (
            <span className="text-sm italic text-color2">
              {' '}
              - {instruction.parameters?.content ?? ''}
            </span>
          )}
        </div>

        <div className="flex gap-2 items-center p-2">
          <Popconfirm
            placement="left"
            title="Opravdu chcete odstranit položku?"
            onConfirm={() => deleteInstruction.mutate({ instructionId: instruction.id })}
            okText="Ano"
            cancelText="Ne"
            icon={null}
          >
            <button type="button">
              <Delete16Regular />
            </button>
          </Popconfirm>

          <button type="button" onClick={() => setShowDialog(true)}>
            <Edit16Regular />
          </button>

          <ArrowBidirectionalUpDown16Regular />
        </div>
      </li>

      {showDialog && (
        <InstructionDialog
          visible={showDialog}
          onSubmit={(_, data) => editInstruction.mutate({ instructionId: instruction.id, data })}
          formProps={instruction}
          onClose={() => setShowDialog(false)}
        />
      )}
    </>
  );
};

export default Instruction;
